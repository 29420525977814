import React from 'react'
import {useHistory} from 'react-router-dom'
import {motion} from 'framer-motion'

const Header = () =>{
    const svgVariants={
        hidden: {
            rotate: -180,
        },
        visible:{
            rotate: 0,
            transition: {duration: 1, staggerChildren: 0.1,}
        }
    }
    const pathVariants= {
        hidden: {
            opacity: 0,
            pathLength: 0,
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            transition: {
                duration: 3,
                ease: 'easeInOut'
            }
        }
    }
    let history = useHistory()
    const handleClick=()=> history.push('/')
    
    return(
        <div onClick={handleClick} className='pointer o-90'>
            <motion.svg 
            className='w3 h3' 
            viewBox="0 0 105 102" 
            fill="none" xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
            initial='hidden'
            animate='visible'
            >
                <g id="aniyi" clipPath="url(#clip0)">
                <motion.path 
                id="left" d="M1.18751 63.1813H-0.0312424V-0.112503H58.0375V1.075H1.18751V63.1813Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="right" d="M105.169 101.419H47.1V100.231H103.95V36.7375H105.169V101.419Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="flower" 
                d="M69.4563 48.2251C69.3938 48.0188 69.2126 47.8563 68.9938 47.8126C64.5313 
                46.9126 59.8126 47.8063 56.0251 50.2125C56.8251 48.9563 57.4688 47.6625 57.9501 46.3313C57.9563 
                46.3313 57.9626 46.3251 57.9626 46.3251C59.3688 45.4001 62.8626 42.9063 64.9688 39.8063C66.2751 37.8813 
                66.6501 36.2688 66.1126 34.8813C66.0063 34.6 65.7001 34.4438 65.4001 34.5125C64.2251 34.7875 63.3251 35.6313 62.6751 
                36.3375C60.2626 38.95 58.7188 42.1063 57.2563 45.1938C57.1938 45.225 57.1376 45.2688 57.0876 45.3188C57.3376 42.1313 
                56.8126 38.825 55.5438 36.4813C55.4188 36.25 55.0876 35.6438 54.425 35.5313C54.0125 35.4625 53.5813 35.6063 53.1938 
                35.9438C52.1188 36.8688 52 38.325 52.025 39.6313C52.1125 43.6876 52.7813 47.7313 53.4563 51.6501C53.2875 51.7313 
                53.1625 51.8938 53.1313 52.0875C51.7 53.7563 50.15 55.1001 48.7938 56.1188C49.2188 55.4438 49.55 54.6751 49.7438 
                53.8376C50.0938 52.3251 50.0438 50.6563 49.6 48.5813C49.325 47.3126 48.8188 45.4626 47.575 44.0501C47.075 43.4813 46.4875 
                43.2688 45.9563 43.4563C45.4688 43.6313 45.25 44.0813 45.1563 44.275C43.9688 46.7313 44.175 49.5438 44.4875 51.7251C44.5688 
                52.2688 44.6563 52.8188 44.7438 53.3626C45.025 55.0501 45.2938 56.7813 45.2063 58.4751C42.2063 60.2188 39.0813 61.5501 
                36.275 62.6438C35.9625 62.7688 35.8125 63.1126 35.9375 63.4188C36.0313 63.6501 36.2625 63.7938 36.5063 63.7938C36.5813 
                63.7938 36.6563 63.7813 36.7313 63.7501C40.1563 62.4063 44.0563 60.7313 47.6688 58.3688C48.0813 58.5501 48.5 58.7313 
                48.9125 58.9188C52.55 60.5376 56.3063 62.2063 60.3376 62.8126C60.4126 62.8251 60.5438 62.8438 60.7063 
                62.8438C60.9938 62.8438 61.3688 62.7751 61.6251 62.4626C61.7938 62.2563 62.0251 61.8063 61.6438 61.0813C60.7563 
                59.4001 59.0501 58.0751 56.8501 57.3438C54.925 56.7001 52.85 56.5751 50.8938 56.5313C50.6688 56.5251 50.4438 
                56.5251 50.2188 56.5251C51.6813 55.3626 52.9813 54.1188 54.1 52.8001C54.4563 52.8188 54.8125 52.8313 
                55.175 52.8313C57.2938 52.8313 59.5813 52.5626 62.2376 52.0001C64.6501 51.4875 67.3751 50.7126 69.2876 
                48.8376C69.4688 48.6563 69.5251 48.4313 69.4563 48.2251ZM46.275 44.7688C46.3375 44.6375 46.375 44.5875 46.3688 
                44.5688C46.4125 44.5875 46.525 44.65 46.6625 44.8125C47.4813 45.7438 48.025 46.9751 48.4188 48.8126C48.825 50.7126 
                48.875 52.2251 48.5688 53.5626C48.2438 54.9876 47.4813 56.1813 46.45 56.9126C46.3813 55.6376 46.1688 54.3813 45.9688 
                53.1501C45.8813 52.6126 45.7875 52.0751 45.7125 51.5376C45.4188 49.5251 45.2188 46.9375 46.275 44.7688ZM56.4751 
                58.4501C58.3813 59.0813 59.8376 60.2063 60.5813 61.6126C60.5813 61.6188 60.5876 61.6188 60.5876 61.6251C60.5751 
                61.6251 60.5626 61.6188 60.5438 61.6188C56.6751 61.0376 52.9938 59.4001 49.4375 57.8188C49.375 57.7938 49.3188 57.7626 
                49.2563 57.7376C49.775 57.6813 50.325 57.6813 50.8875 57.7001C53.0313 57.7438 54.8 57.8938 56.4751 58.4501ZM63.5938 
                37.1251C64.0251 36.6563 64.5313 36.1625 65.1251 35.8688C65.2251 36.7688 64.8438 37.8438 63.9626 39.1438C62.9063 40.7001 
                61.2001 42.4188 59.2001 43.9688C60.4001 41.5313 61.7376 39.1376 63.5938 37.1251ZM54.0063 36.825C54.1313 36.7188 54.2188 
                36.6938 54.225 36.6938C54.2313 36.6938 54.3188 36.7313 54.4751 37.0251C56.2563 40.3188 56.4251 45.9251 54.8625 49.8001C54.7375 
                49.9876 54.6125 50.1751 54.4813 50.3563C53.8813 46.8188 53.325 43.2001 53.25 39.6C53.2313 38.5688 53.3063 37.4313 54.0063 
                36.825ZM62.0001 50.8126C59.8063 51.2751 57.8813 51.5376 56.1063 51.6001C58.8313 49.7001 62.2438 48.6626 65.6188 
                48.6626C66.2376 48.6626 66.8563 48.7001 67.4751 48.7688C65.8688 49.8751 63.8376 50.4251 62.0001 50.8126Z" fill="#F4F4F4"  
                
                variants={pathVariants}
                />
                <g id="niyi">
                <motion.path id="Vector" 
                d="M81.55 12.9H79.625L76.5187 7.50001H76.475C76.5375 8.45626 76.5687 9.13126 
                76.5687 9.54376V12.9063H75.2187V5.75626H77.125L80.225 11.1H80.2562C80.2062 10.175 80.1812 9.51876 80.1812 
                9.13126V5.75626H81.5437V12.9H81.55Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_2" d="M83.7 12.9V5.75626H85.2125V12.8938H83.7V12.9Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_3" 
                d="M89.5812 8.70001L91.0688 5.7625H92.7001L90.3313 10.125V12.9063H88.825V10.175L86.4562 5.76875H88.1L89.5812 8.70001Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_4" d="M93.95 12.9V5.75626H95.4626V12.8938H93.95V12.9Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                </g>
                <g id="aromokeye">
                <motion.path id="Vector_5" 
                d="M12.6 95.5872L12.0813 93.8872H9.48126L8.9625 95.5872H7.33125L9.85001 88.4184H11.7L14.2313 
                95.5872H12.6ZM11.7188 92.6184C11.2375 91.0809 10.9688 90.2059 10.9125 90.0059C10.85 89.8059 
                10.8063 89.6434 10.7813 89.5246C10.675 89.9434 10.3688 90.9747 9.85626 92.6184H11.7188V92.6184Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_6" d="M16.9438 92.8313V95.5688H15.4313V88.4313H17.5125C18.4813 
                88.4313 19.2 88.6063 19.6688 88.9626C20.1313 89.3188 20.3688 89.8501 20.3688 90.5688C20.3688 
                90.9876 20.25 91.3626 20.025 91.6876C19.7938 92.0126 19.4688 92.2688 19.0438 92.4563C20.1188 94.0626 
                20.8188 95.1001 21.1438 95.5688H19.4625L17.7563 92.8313H16.9438V92.8313ZM16.9438 91.6001H17.4313C17.9125 91.6001 
                18.2625 91.5188 18.4938 91.3626C18.7188 91.2063 18.8375 90.9501 18.8375 90.6126C18.8375 90.2751 18.7188 90.0313 18.4875 
                89.8876C18.2563 89.7438 17.8938 89.6751 17.4063 89.6751H16.95V91.6001H16.9438Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_7" d="M28.8063 91.9876C28.8063 93.1689 28.5125 94.0751 27.925 94.7126C27.3375 
                95.3501 26.5 95.6626 25.4063 95.6626C24.3125 95.6626 23.475 95.3439 22.8875 94.7126C22.3 94.0814 22.0062 93.1689 
                22.0062 91.9751C22.0062 90.7814 22.3 89.8814 22.8875 89.2501C23.475 88.6251 24.3188 88.3126 25.4125 88.3126C26.5063 
                88.3126 27.35 88.6251 27.9313 89.2626C28.5125 89.9001 28.8063 90.8064 28.8063 91.9876ZM23.6 91.9876C23.6 92.7876 
                23.75 93.3876 24.0563 93.7876C24.3625 94.1876 24.8125 94.3939 25.4125 94.3939C26.6188 94.3939 27.225 93.5939 
                27.225 91.9876C27.225 90.3814 26.625 89.5751 25.425 89.5751C24.825 89.5751 24.3688 89.7751 24.0625 90.1814C23.75 
                90.5876 23.6 91.1939 23.6 91.9876Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_8" d="M33.6125 95.5688L31.8937 
                89.9688H31.85C31.9125 91.1063 31.9437 91.8688 31.9437 92.2501V95.5688H30.5937V88.4313H32.6562L34.3437 
                93.8876H34.375L36.1687 88.4313H38.2312V95.5688H36.8187V92.1876C36.8187 92.0313 36.8187 91.8438 36.825 
                91.6376C36.8312 91.4313 36.85 90.8751 36.8937 89.9751H36.85L35 95.5688H33.6125Z" fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_9" d="M46.7937 91.9876C46.7937 
                93.1689 46.5 94.0751 45.9125 94.7126C45.325 95.3501 44.4875 95.6626 43.3937 95.6626C42.3 95.6626 
                41.4625 95.3439 40.875 94.7126C40.2875 94.0814 40 93.1689 40 91.9814C40 90.7938 40.2938 89.8876 40.8813 
                89.2563C41.4688 88.6313 42.3125 88.3188 43.4062 88.3188C44.5 88.3188 45.3437 88.6313 45.925 89.2688C46.5062 
                89.9063 46.7937 90.8063 46.7937 91.9876ZM41.5875 91.9876C41.5875 92.7876 41.7375 93.3876 42.0437 93.7876C42.35 
                94.1876 42.8 94.3939 43.4 94.3939C44.6062 94.3939 45.2125 93.5939 45.2125 91.9876C45.2125 90.3813 44.6125 89.5751 
                43.4125 89.5751C42.8125 89.5751 42.3562 89.7751 42.05 90.1813C41.7375 90.5876 41.5875 91.1938 41.5875 91.9876Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path id="Vector_10" d="M54.3188 95.5688H52.6L50.7312 92.5626L50.0937 
                93.0188V95.5688H48.5812V88.4313H50.0937V91.7001L50.6875 90.8626L52.6188 88.4376H54.3L51.8125 91.5938L54.3188 95.5688Z" 
                fill="#F4F4F4"  
                variants={pathVariants}/>
                <motion.path id="Vector_11" 
                d="M59.6251 95.5688H55.5125V88.4313H59.6251V89.6688H57.025V91.2376H59.4438V92.4751H57.025V94.3188H59.6251V95.5688Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path 
                id="Vector_12" 
                d="M63.6375 91.3688L65.125 88.4313H66.7562L64.3875 92.7938V95.5751H62.8812V92.8439L60.5125 88.4376H62.1562L63.6375 91.3688Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                <motion.path 
                id="Vector_13" 
                d="M72.0687 95.5688H67.9562V88.4313H72.0687V89.6688H69.4687V91.2376H71.8875V92.4751H69.4687V94.3188H72.0687V95.5688Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                </g>
                <g id="LetterN">
                <motion.path id="Vector_14" 
                d="M31.2875 30.7938H24.175L16.8375 
                16.65H16.7125C16.8875 18.875 16.975 20.575 16.975 21.7438V30.7938H12.1687V10.7188H19.2562L26.5625 24.6688H26.6437C26.5125 
                22.6438 26.45 21.0188 26.45 19.7938V10.7188H31.2812V30.7938H31.2875Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                </g>
                <g id="letterA">
                <motion.path id="Vector_15" d="M88.3313 84.3313L87.3438 80.5688H80.8188L79.8 
                84.3313H73.8375L80.3875 64.1688H87.625L94.2563 84.3313H88.3313ZM86.2125 76.1188L85.35 
                72.8251C85.15 72.0938 84.9063 71.1438 84.6125 69.9813C84.325 68.8188 84.1313 67.9876 84.0438 
                67.4813C83.9625 67.9501 83.8 68.7188 83.5563 69.7876C83.3125 70.8563 82.775 72.9688 81.9438 76.1188H86.2125Z" 
                fill="#F4F4F4"  
                variants={pathVariants}
                />
                </g>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="105" height="101.25" fill="white"/>
                </clipPath>
                </defs>
            </motion.svg>

        </div>
    )
}

export default Header